<template>
  <vx-card>
    <!-- HEADER -->
    <div class="grid grid-cols-3">
      <div>
        <vs-checkbox v-model="watermark"
          >ลายน้ำรับรองว่าตรวจสอบทุกรายการ</vs-checkbox
        >
      </div>
      <div>
        <!-- <vs-checkbox  v-model="settlementBox">กล่องสรุปการชำระเงินและลายเซ็นลูกค้า</vs-checkbox> -->
      </div>
    </div>

    <div
      id="facility-card"
      class="grid-layout-container alignment-block"
      style="font-family: mitr; vertical-align:text-top ;width:100%; padding: 10px ; margin-bottom: 20px;"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="3"
          vs-sm="3"
          vs-xs="3"
        >
          <!-- เลขประจำตัวผู้เสียภาษี -->
          <!-- <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >เลขประจำตัวผู้เสียภาษี : 1234567890123
          </vs-col>
          </vs-row> -->
          <!-- ชื่อผู้เช่า -->
          <vs-row vs-w="12">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
              >ชื่อผู้เช่า</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              >{{ this.customerName }}</vs-col
            >
          </vs-row>
          <!-- เบอร์ติดต่อ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
              >เบอร์ติดต่อ</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              >{{ this.customerPhone }}</vs-col
            >
          </vs-row>
          <!-- ที่อยู่ -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
              >ที่อยู่</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              >{{ this.customerAddress }}</vs-col
            >
          </vs-row>
          <!-- กำหนดรับ -->
          <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
              >กำหนดรับ</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              >{{ format_ddmmyyy(this.startDate) }}</vs-col
            >
          </vs-row>
          <!-- กำหนดคืน -->
          <vs-row vs-w="12" style="margin-top:5px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
              >กำหนดคืน</vs-col
            >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              >{{ format_ddmmyyy(this.endDate) }}</vs-col
            >
          </vs-row>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="5"
          vs-sm="5"
          vs-xs="5"
        >
          <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
            <!-- ใบเสร็จรับเงิน -->
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
              <vs-col
                class="theLabel px-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <div style="font-size: 24px; font-weight: bold;">
                  {{ taxInfo.receiptHeader }}
                </div>
              </vs-col>
            </vs-row>
            <!-- เบอร์ติดต่อ -->
            <vs-row vs-w="12" style="margin-top: 5px;">
              <vs-col
                class="theLabel px-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                เลขที่ &nbsp;&nbsp;
                <span class="pl-2" style="font-weight: light;">{{
                  this.orderNumber
                }}</span>
              </vs-col>
            </vs-row>
            <!-- วันที่ออก -->
            <vs-row vs-w="12" style="margin-top: 5px;">
              <vs-col
                class="theLabel px-2"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                >วันที่ออก &nbsp;
                {{
                  format_ddmmyyy(orderInfo.orderHeader.createDateTime)
                }}</vs-col
              >
            </vs-row>
            <vs-row vs-w="12" style="margin-top: 5px;">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <div>
                  <vue-barcode
                    style="width:100%;"
                    v-bind:value="orderNumber"
                    tag="img"
                    >ไม่มีบาร์โค้ด</vue-barcode
                  >
                </div>
              </vs-col>
            </vs-row>
          </vs-row>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-start"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
          >
            <img style="width: 150px;" :src="theLogo()" />
          </vs-row>
          <!-- เลขประจำตัวผู้เสียภาษี -->
          <!-- <vs-row vs-w="12" style="margin-top:30px;">
            <vs-col
              class="theLabel px-2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >เลขประจำตัวผู้เสียภาษี : 1234567890123
          </vs-col>
          </vs-row> -->
          <vs-row
            style="margin-top: 10px; text-align:right;  font-weight: bold;"
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
            >{{ shopinfo.name }} {{ aBranch.branchName }}</vs-row
          >
          <vs-row
            style=" text-align:right;"
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
            >{{ aBranch.address }}</vs-row
          >

          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
            style="text-align:right;"
            >โทร. {{ aBranch.phone }}</vs-row
          >
          <vs-row
            v-if="
              shopinfo.line !== null &&
                shopinfo.line !== undefined &&
                shopinfo.line !== ''
            "
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
            >Line: {{ shopinfo.line }}
          </vs-row>
          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-align="flex-end"
            vs-justify="flex-end"
            >เปิด {{ aBranch.workingTime }}</vs-row
          >
        </vs-col>
      </vs-row>
    </div>
    <div
      v-if="watermark === true"
      style="position:absolute; margin-top:50px ; margin-left: 20%;width: 50%; z-index: 999;"
    >
      <img style=" width: 100%;" :src="alreadycheckedImage()" />
    </div>

    <!-- ตาราง -->
    <vs-table hoverFlat stripe :data="orders">
      <template slot="thead">
        <vs-th>รูปตัวอย่าง</vs-th>
        <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
        <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
        <vs-th style="text-align:center;" sort-key="rentalPrice"
          >ราคาเช่า</vs-th
        >
        <vs-th style="text-align:center;" sort-key="bail">เงินประกัน</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          class="table-font"
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in orders"
        >
          <vs-td :data="data[indextr].mainImage">
            <img
              class="responsive rounded"
              :src="getImagepath(data[indextr].mainImage)"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>

          <vs-td :data="data[indextr].name">
            {{ data[indextr].name }}
            <br />

            <span style="font-size:9px;">({{ data[indextr].code }})</span>
            <br />
            <i
              v-if="getRemoved(indextr) === 'y'"
              class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
              style="order: 0;text-align:left;  font-size:25px; color:red; cursor:pointer;"
              >cancel</i
            >
            <span
              v-if="getRemoved(indextr) === 'k'"
              style="font-size:0.75rem; color:blue;"
              >(มูลค่าของรายการนี้ถูกเก็บสิทธิ์)</span
            >
          </vs-td>

          <vs-td :data="data[indextr].reservedQuantity">
            {{ data[indextr].reservedQuantity }}
          </vs-td>

          <vs-td :data="data[indextr].rentalPrice">
            <span style="padding:14px 10px;">
              {{
                formatPrice(
                  data[indextr].rentalPrice * data[indextr].reservedQuantity
                )
              }}
            </span>
          </vs-td>

          <vs-td :data="data[indextr].bail">
            <span style="padding:14px 10px;">
              {{
                formatPrice(data[indextr].bail * data[indextr].reservedQuantity)
              }}
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- BEGIN: MONETARY SUMMATION -->
    <div
      class="grid-layout-container alignment-block"
      style="width:100%; margin-top: 20px;"
    >
      <vs-row vs-w="12">
        <vs-col
          v-if="
            shopinfo.termAndCondition !== null &&
              shopinfo.termAndCondition !== undefined &&
              shopinfo.termAndCondition !== ''
          "
          style="border: solid 2px red;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="8"
          vs-sm="8"
          vs-xs="8"
        >
          <div
            v-if="shopinfo.termAndCondition"
            style="padding: 10px; font-family: mitr; color:black ; font-size: 0.8rem ;"
            v-html="shopinfo.termAndCondition"
          ></div>
        </vs-col>
        <vs-col
          v-else
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="8"
          vs-sm="8"
          vs-xs="8"
        >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <div
            class="grid-layout-container alignment-block"
            style="width:100%; margin-top: 20px;"
          >
            <!-- <vs-row   vs-w="12" style="padding: 5px 20px; " vs-justify="flex-end" class="grand-total-discount  the-black">
              ยอดสรุปทั้งหมดมีหน่วยเป็น: บาท
            </vs-row> -->
            <!-- ยอดรวมตามรายการ -->
            <vs-row
              vs-w="12"
              style="padding: 5px 20px; "
              class="grand-total the-black"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="font-family:mitr ;"
                >ยอดเช่ารวม</vs-col
              >
              <vs-col
                style="padding-left: 30px; font-family:mitr ;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(rentalPriceTotal) }}
                <span class="pl-2" style="font-size: 0.5rem;">
                  บาท</span
                ></vs-col
              >
            </vs-row>
            <!-- ส่วนลดค่าเช่า -->
            <vs-row
              v-if="
                rentalDiscount > 0 &&
                  rentalDiscount != undefined &&
                  rentalDiscount != null
              "
              vs-w="12"
              style="padding: 0 20px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="font-size:0.9rem;"
                >ส่วนลดค่าเช่า</vs-col
              >
              <vs-col
                style="padding-left: 30px; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(rentalDiscount) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>

            <!-- ใช้ Voucher -->
            <!--
            <vs-row
              v-if="
                voucherDiscount > 0 &&
                  voucherDiscount != undefined &&
                  voucherDiscount != null
              "
              vs-w="12"
              style="padding: 0 20px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="font-size:0.9rem;"
              >ใช้ Voucher</vs-col>
              <vs-col
                style="padding-left: 30px; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{ formatPrice(voucherDiscount) }} </vs-col>
            </vs-row>
            -->

            <!-- ภาษีมูลค่าเพิ่ม -->
            <vs-row
              v-if="vatSystem === true"
              vs-w="12"
              style="padding: 5px 20px; "
              class="grand-total the-black"
            >
              <!-- VAT  -->
              <!-- 2021-04-07 -->

              <vs-col
                style="font-weight: 200; font-size:0.9rem; font-family:mitr ;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >VAT ({{ taxRate }}%)
              </vs-col>

              <!-- <vs-col
                style="padding-left: 30px; font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
              {{ formatPrice(vat_final(rentalPriceTotal, rentalDiscount)) }} </vs-col> -->

              <vs-col
                style="padding-left: 30px; font-weight: 200 !important; font-size:0.9rem; font-family:mitr ; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >
                {{ formatPrice(vat) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>
            <vs-row
              v-if="vatSystem === true"
              vs-w="12"
              style="padding: 5px  20px; "
              class="grand-total the-black"
            >
              <!-- Before -->

              <vs-col
                style="font-weight: 200 !important; font-size:0.9rem; font-family:mitr ;"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >Before VAT</vs-col
              >
              <!-- <vs-col
                style="padding-left: 30px; font-weight: 200 !important; font-size:0.9rem; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
              >{{ formatPrice(beforeVat_final(rentalPriceTotal, rentalDiscount)) }} </vs-col> -->

              <vs-col
                style="padding-left: 30px; font-weight: 200 !important; font-size:0.9rem; font-family:mitr ;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(beforeVat) }}
                <span class="pl-2" style="font-size: 0.5rem;">
                  บาท</span
                ></vs-col
              >
            </vs-row>

            <!-- รวมค่าเช่า -->
            <vs-row
              v-if="vatSystem === true"
              vs-w="12"
              style="padding: 5px 20px; "
              class="grand-total the-black"
            >
              <!-- GRAND TOTAL MONETARY SUMMATION -->

              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="font-family:mitr ;"
                >ยอดเช่าสุทธิ</vs-col
              >

              <vs-col
                style="padding-left: 30px; font-size:1.1rem; font-family:mitr ;  "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(netPrice) }}
                <span class="pl-2" style="font-size: 0.5rem;">
                  บาท</span
                ></vs-col
              >
            </vs-row>

            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 1px solid #f4f4f4; "
                >&nbsp;</vs-col
              >
              <vs-col
                style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >&nbsp;</vs-col
              >
            </vs-row>
            <!-- รวมค่าประกัน -->
            <vs-row
              vs-w="12"
              style="padding: 10px 20px;  font-family:mitr ;"
              class="grand-total the-black"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="  "
                >ยอดรวมค่าประกัน</vs-col
              >
              <vs-col
                style="padding-left: 30px;   "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(bailTotal) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>
            <!-- ส่วนลดค่าประกัน -->
            <vs-row
              v-if="
                bailDiscount > 0 &&
                  bailDiscount != undefined &&
                  bailDiscount != null
              "
              vs-w="12"
              style="padding: 0 10px; "
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style=" font-size:0.9rem; font-family:mitr ; "
                >ส่วนลดค่าประกัน</vs-col
              >
              <vs-col
                style="padding-left: 30px;  font-size:0.9rem; font-family:mitr ;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(bailDiscount) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>

            <!-- โปรโมชั่น -->
            <vs-row
              v-if="
                promotionDiscount > 0 &&
                  promotionDiscount != undefined &&
                  promotionDiscount != null
              "
              vs-w="12"
              style="padding: 0 20px;"
              class="grand-total-discount the-red"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style=" font-family:mitr ; "
                >โปรโมชั่น</vs-col
              >
              <vs-col
                style="padding-left: 30px;  font-family:mitr ; "
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >{{ formatPrice(promotionDiscount) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>
            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
                >&nbsp;</vs-col
              >
              <vs-col
                style="padding-left: 30px; border-bottom: 1px solid #f4f4f4; padding:0;height:15px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >&nbsp;</vs-col
              >
            </vs-row>
            <!-- ยอดรวมทั้งหมด -->
            <vs-row
              vs-w="12"
              style="padding: 0 20px; margin-top:10px; font-family:mitr ; "
              class="grand-total the-blue"
            >
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >ยอดรวมทั้งหมด</vs-col
              >
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="padding-left:30px; font-family:mitr ;"
                >{{ formatPrice(grandTotal) }}
                <span class="pl-2" style="font-size: 0.5rem;"> บาท</span>
              </vs-col>
            </vs-row>

            <!-- ขีดเส้นใต้ -->
            <vs-row vs-w="12" style="height: 10px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                style="border-bottom: 4px solid #f0f0f0; padding:0;height:20px;"
                >&nbsp;</vs-col
              >
              <vs-col
                style="border-bottom: 4px solid #f0f0f0;  padding-left: 30px;  padding:0;height:20px;"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                >&nbsp;</vs-col
              >
            </vs-row>
            <!-- เว้นช่องไฟ -->
            <vs-row vs-w="12" style="margin-top:20px; height: 20px !important;">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                style=" padding:0;height:15px; font-size: 0.75rem;"
              >
                <span
                  v-if="
                    voucherDiscount > 0 &&
                      voucherDiscount != undefined &&
                      voucherDiscount != null
                  "
                  >ส่วนลด voucher:<br />
                  {{ formatPrice(voucherDiscount) }}</span
                >
                <span
                  v-if="
                    pointDiscount > 0 &&
                      pointDiscount != undefined &&
                      pointDiscount != null
                  "
                  class="ml-2"
                  >&nbsp;ใช้พอยต์:<br />
                  {{ formatPrice(pointDiscount) }}
                </span>
                <span
                  v-if="
                    getPoint > 0 && getPoint != undefined && getPoint != null
                  "
                  class="ml-2"
                  >&nbsp;ได้พอยต์:<br />
                  {{ formatPrice(getPoint) }}
                </span>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top:10px;" vs-w="12">
        <i
          style="font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; "
          class="material-icons iconhover"
          >local_shipping</i
        >
        วิธีรับชุด : {{ receivingMethod }}
      </vs-row>
      <vs-row style="margin-top:5px;" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="7"
          vs-sm="7"
          vs-xs="12"
        >
          หมายเหตุ :
          {{ remark1 }}
        </vs-col>
      </vs-row>
    </div>
    <!-- END: MONETARY SUMMATION -->

    <!--------------------------------------------------------------------->
    <!-- ลายเซ็น -->
    <!--------------------------------------------------------------------->
    <div
      v-if="settlementBox"
      class="grid-layout-container alignment-block"
      style="vertical-align:text-top ;width:100%; margin-top:50px;margin-bottom:50px;"
    >
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <div
            style="border: 1px solid #979797 ; border-radius: 5px; width: 95%; min-height: 300px;"
          >
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div style="font-family:mitr ; font-size: 20px;">ค่าเช่าชุด</div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div
                v-if="this.netPrice > 0 || this.netPrice != null"
                style="font-family:mitr ; font-size: 16px;"
              >
                ชำระ: {{ formatPrice(this.netPrice) }}
              </div>
              <div v-else style="font-family:mitr ; font-size: 16px;">
                ชำระ: {{ formatPrice(this.theLastTotalPrice()) }}
              </div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <img style="width:90%;" :src="rentalImage()" />
            </vs-row>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <div
            style="border: 1px solid #979797 ; border-radius: 5px; width: 95%;  min-height: 300px;"
          >
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div style="font-family:mitr ; font-size: 20px;">ค่าประกัน</div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div
                v-if="this.bailNetTotal > 0 || this.bailNetTotal != null"
                style="font-family:mitr ; font-size: 16px;"
              >
                ชำระ: {{ formatPrice(this.bailNetTotal) }}
              </div>
              <div v-else style="font-family:mitr ; font-size: 16px;">
                ชำระ: {{ formatPrice(this.theLastBail()) }}
              </div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <img style="width:90%;" :src="rentalImage()" />
            </vs-row>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <!-- -->
          <div
            style="border: 1px solid #979797 ; border-radius: 5px; width: 95%;  min-height: 300px;"
          >
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div style="font-family:mitr ; font-size: 20px;">
                คืนเงินประกัน
              </div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <div style="font-family:mitr ; font-size: 16px;">
                ชำระ: . . . . . . . . . . .
              </div>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-justify="center">
              <img style="width:90%;" :src="returnImage()" />
            </vs-row>
          </div>
          <!-- -->
        </vs-col>
      </vs-row>
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        ></vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        ></vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="12"
        >
          <!-- <span style="color:#D0021B; font-size: 11px;">
            (*** การคืนค่าประกัน : ทางร้านจะโอนคืนให้เท่านั้น โดยจะโอนคืน
            ไม่เกิน 22.00 น. ของวันที่ลูกค้านำชุดมาคืน)
          </span> -->
          <!-- <span style="color:#D0021B;font-size: 11px;"></span> -->
        </vs-col>
      </vs-row>
    </div>

    <!--------------------------------------------------------------------->
    <!--------------------------------------------------------------------->
    <div style="border-top: 1px solid #E3E3E3; margin-bottom:40px; "></div>
    <!--------------------------------------------------------------------->

    <!--  ปุ่ม -->
    <vs-row vs-w="12" vs-type="flex" vs-justify="center">
      <vs-button
        v-if="orderStatus === 'บันทึก'"
        @click="backtorental()"
        style="margin-right: 20px;"
        color="warning"
        type="filled"
        >กลับไปหน้าเช่า</vs-button
      >
      <vs-button
        style="margin-right: 20px;"
        v-if="this.orderStatus === 'บันทึก'"
        @click="update()"
        color="success"
        type="filled"
        >ยืนยันการเช่า</vs-button
      >
      <!-- <vs-button
        v-if="this.orderStatus!=='บันทึก' && this.orderStatus!=='' && this.orderStatus!==null  && this.orderStatus!==undefined "
        :href="getRedirectLink(this.orderHeaderId)"
        target="blank"
        color="danger"
        type="filled"
      >พิมพ์</vs-button>-->
      <vs-button
        v-if="
          orderStatus !== 'บันทึก' &&
            orderStatus !== '' &&
            orderStatus !== null &&
            orderStatus !== undefined
        "
        :href="getRedirectLink2(orderHeaderId)"
        target="blank"
        color="primary"
        type="filled"
        style="margin-left:30px;"
        class="mt-6"
      >
        <i
          style="font-size:18px; margin-right:5px;vertical-align:middle;"
          class="material-icons"
          >print</i
        >

        พิมพ์ใบเสร็จแบบเต็ม</vs-button
      >

      <vs-button
        v-if="
          orderStatus !== 'บันทึก' &&
            orderStatus !== '' &&
            orderStatus !== null &&
            orderStatus !== undefined
        "
        :href="getRedirectLinkAbbReceipt(orderHeaderId)"
        target="blank"
        color="success"
        type="filled"
        style="margin-left:30px;"
        class="mt-6"
      >
        <i
          style="font-size:18px; margin-right:5px;vertical-align:middle;"
          class="material-icons"
          >receipt</i
        >

        พิมพ์ใบเสร็จแบบย่อ</vs-button
      >

      <vs-button
        v-if="
          orderStatus !== 'บันทึก' &&
            orderStatus !== '' &&
            orderStatus !== null &&
            orderStatus !== undefined
        "
        :href="getRedirectLinkToSticker(orderHeaderId)"
        target="blank"
        color="danger"
        type="filled"
        style="margin-left:30px;"
        class="mt-6"
      >
        <i
          style="font-size:18px; margin-right:5px;vertical-align:middle;"
          class="material-icons"
          >local_post_office</i
        >

        ใบปะหน้าส่งของ</vs-button
      >
    </vs-row>
    <!--------------------------------------------------------------------->
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";

// import jsPDF from "jspdf";

// import html2canvas from "html2canvas";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  data() {
    return {
      shopLineScore: 0,
      itemRecordScore: 0,
      orderInfo: {},
      detail: [],
      aBranch: {},
      termandcondition: "",
      settlementBox: true,
      watermark: false,
      startDate: null,
      endDate: null,

      orderHeaderId: "",
      issuedDate: new Date(),
      displayItems: [],
      branchStock: [],
      itemsInBranch: [],
      // 1. ORDER HEADER
      orderNumber: "",
      orderStatus: "", // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed
      remark: "",
      remark1: "",

      // 2. CUSTOMER
      customerInfo: "",
      customerName: "",
      customerAddress: "",
      customerPhone: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      transferContact: "",

      // 3. DELIVERY
      howtoreceive: "มารับเอง", // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
      receivingDate: null,
      receivingMethod: "",
      returnDate: null,
      addtocart: "",

      // 4. MONETARY
      rentalPriceTotal: 0,
      bailTotal: 0,
      grandTotal: 0,
      bailDiscount: 0,
      rentalDiscount: 0,
      promotionDiscount: 0,
      rentalDiscountPercent: 0,
      bailDiscountPercent: 0,

      deduction: 0,
      deducingDate: null,

      //*** BEGIN 15Nov2021 เพิ่มภาษี */
      voucherDiscount: 0,
      pointDiscount: 0,
      netPrice: 0,
      vat: 0,
      beforeVat: 0,
      getPoint: 0,

      vatSystem: false,
      inVat: false,
      taxRate: 7,
      //*** END 15Nov2021 เพิ่มภาษี */

      // 5. LIST
      originalList: [],
      select_order: "OR1903130042",
      order_option: [
        { text: "OR1903130042", value: "OR1903130042" },
        { text: "OR1903130041", value: "OR1903130041" },
        { text: "OR1903130040", value: "OR1903130040" }
      ],
      orders: [],
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      }
    };
  },

  async mounted() {
    //*** 2021-03-09 : BEGIN For ABB RECEIPT
    // this.shopInfo = await this.getShopInfo();
    //*** 2021-03-09 : END For getShopLineScore
  },
  methods: {
    // *** 2021-04-013 ระบภาษี
    // *** rentalPriceTotal1 = this.rentalPriceTotal

    // ยอดเช่าสุทธิ

    // rentalPriceTotal_final(){
    //   //  CASE1 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === false)
    //     return this.rentalPriceTotal1();
    //   //  CASE2 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคาไม่รวม
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === false)
    //     return this.rentalPriceTotal2();
    //   //  CASE3 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === true)
    //     return this.rentalPriceTotal3();
    //   //  CASE4 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคาไม่รวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === true)
    //     return this.rentalPriceTotal4();
    // },
    // vat_final(){
    //   //  CASE1 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === false)
    //     return this.vat1();
    //   //  CASE2 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคาไม่รวม
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === false)
    //     return this.vat2();
    //   //  CASE3 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === true)
    //     return this.vat3();
    //   //  CASE4 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคาไม่รวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === true)
    //     return this.vat4();
    // },
    // beforeVat_final(){
    //   //  CASE1 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === false)
    //     return this.beforeVat1();
    //   //  CASE2 กรณี อยู่ในระบบ VAT | ไม่มีส่วนลด | ราคาไม่รวม
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === false)
    //     return this.beforeVat2();
    //   //  CASE3 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคารวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===true && this.isRentalDiscount() === true)
    //     return this.beforeVat3();
    //   //  CASE4 กรณี อยู่ในระบบ VAT | มีส่วนลด | ราคาไม่รวม VAT
    //   if(this.taxInfo.vatSystem===true && this.taxInfo.inVat===false && this.isRentalDiscount() === true)
    //     return this.beforeVat4();
    // },
    // beforeVat1(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT1)
    //   return this.rentalPriceTotal - this.vat1();
    // },
    // vat1(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT1)
    //   return this.rentalPriceTotal * (this.taxInfo.taxRate / (100 + this.taxInfo.taxRate) );
    // },
    // rentalPriceTotal1(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT1)
    //   return this.rentalPriceTotal ;

    // },
    // // ================================

    // beforeVat2(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT2)
    //   return this.rentalPriceTotal;
    // },
    // vat2(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT2)
    //   return this.rentalPriceTotal * (this.taxInfo.taxRate / 100);
    // },
    // rentalPriceTotal2(){
    //   // ไม่มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT2)
    //   return this.rentalPriceTotal + this.vat2();

    // },
    // // ================================
    // beforeVat3(){
    //   // มีส่วนลด ราคาในสต็อครวมภาษี (VAT3)
    //   return (this.rentalPriceTotal * 100) / (100 + this.taxInfo.taxRate );
    // },
    // vat3(){
    //   // มีส่วนลด ราคาในสต็อครวมภาษี (VAT3)
    //   var discount = 0;
    //   if(this.isRentalDiscount()){
    //       discount = this.rentalDiscount;
    //   }
    //   return (this.beforeVat3() -  discount) * (this.taxInfo.taxRate  / 100);
    // },
    // rentalPriceTotal3(){
    //   // มีส่วนลด ราคาในสต็อครวมภาษี (VAT3)
    //   var discount = 0;
    //   if(this.isRentalDiscount()){
    //       discount = this.rentalDiscount;
    //   }

    //   return (this.beforeVat3() -  discount) + this.vat3();

    // },
    // // ================================
    //  beforeVat4(){
    //   // มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT4)
    //   return this.rentalPriceTotal ;
    // },
    // vat4(){
    //   // มีส่วนลด ราคาในสต็อคไม่รวมภาษี (VAT4)
    //   var discount = 0;
    //   if(this.isRentalDiscount()){
    //       discount = this.rentalDiscount;
    //   }

    //   return  (this.beforeVat4() -  discount) * (this.taxInfo.taxRate  / 100);
    // },
    // rentalPriceTotal4(){
    //   // มีส่วนลด ราคาในสต็อคไ่ม่รวมภาษี (VAT4)
    //   var discount = 0;
    //   if(this.isRentalDiscount()){
    //       discount = this.rentalDiscount;
    //   }

    //   return (this.beforeVat4() -  discount) + this.vat4();
    // },
    // isRentalDiscount(){
    //      if(
    //           this.rentalDiscount > 0 &&
    //               this.rentalDiscount != undefined &&
    //               this.rentalDiscount != null
    //      )
    //      return true;
    //      else return false;
    // },
    // *** 2021-04-013 ระบภาษี

    getRedirectLinkToSticker() {
      //*** 2021-03-09 : BEGIN For ABB RECEIPT

      var path = "/pages/postal-sticker/" + this.orderHeaderId;

      //  console.log(' abb rct path >>> ',path);

      return path;
    },

    getRedirectLinkAbbReceipt() {
      //*** 2021-03-09 : BEGIN For ABB RECEIPT

      // var weighted_score = this.calculateScoreForAbbReceipt(
      //   this.shopLineScore,
      //   this.displayItems.length
      // );

      //*** 2021-03-09 : END For ABB RECEIPT

      //*** ABB RECEIPT
      // var path =
      //   "/pages/abb-receipt/" +
      //   weighted_score.toString() +
      //   "/or/" +
      //   this.orderHeaderId;


      var path =
        "/pages/abb-receipt-common/" +
        this.orderHeaderId;

      //  console.log(' abb rct path >>> ',path);

      return path;
    },
    getRedirectLink(orderId) {
      var ff = "t";
      if (this.watermark === false) {
        ff = "f";
      } else {
        ff = "t";
      }
      return "/pages/rec/" + orderId + "/" + ff;
    },
    getRedirectLink2(orderId) {
      var ff = "t";
      if (this.watermark === false) {
        ff = "f";
      } else {
        ff = "t";
      }
      return "/pages/rec2/" + orderId + "/" + ff;
    },
    theLogo() {
      if (
        this.shopinfo.logo === undefined ||
        this.shopinfo.logo === "" ||
        this.shopinfo.logo === null
      ) {
        return this.$store.state.defaultStore + "logo.png";
      } else {
        // console.log('this.$store.state.defaultStore  + this.shopinfo.logo >>> ',this.$store.state.defaultStore  + this.shopinfo.logo);

        return this.$store.state.defaultStore + this.shopinfo.logo;
      }
    },
    getRemoved(ind) {
      return this.detail[ind].flag1;
    },
    async loadShopInfo() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res;
    },

    theLastTotalPrice() {
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var total = isNaN(Number(this.rentalPriceTotal))
        ? 0
        : Number(this.rentalPriceTotal);

      // console.log("total : ", total, " pd : ", pd, " rd : ", rd);

      if (this.taxInfo.vatSystem === false) return total - (pd + rd);
      else {
        return this.rentalPriceTotal_final(
          this.rentalPriceTotal,
          this.rentalDiscount
        );
      }
    },

    // *** BEGIN TAXXXXXXXXXXXXX ===========

    // ***  ยังไม่รวม VAT
    includeVat() {
      // ***  กรณีสินค้าในสต็อคยังไม่รวม VAT แล้วต้องการวม VAT ให้ดู
      var invat = (this.rentalPriceTotal * (100 + this.taxInfo.taxRate)) / 100;
      return invat;
    },

    includeVat_tax() {
      // ***  กรณีสินค้าในสต็อคยังไม่รวม VAT แล้วต้องการวม TAX ให้ดู
      var tax = (this.rentalPriceTotal * this.taxInfo.taxRate) / 100;
      return tax;
    },

    // ***  รวม VAT ไปแล้ว
    notIncludeVat() {
      // ***  กรณีสินค้าในสต็อครวม VAT แล้วต้องการแยก VAT ให้ดู
      var invat = (this.rentalPriceTotal * 100) / (100 + this.taxInfo.taxRate);
      return invat;
    },

    notIncludeVat_tax() {
      // ***  กรณีสินค้าในสต็อครวม VAT แล้ว ค่า VAT
      var tax =
        (this.rentalPriceTotal * this.taxInfo.taxRate) /
        (100 + this.taxInfo.taxRate);
      return tax;
    },

    // *** END TAXXXXXXXXXXXXX ===========

    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    conditionImage() {
      return this.$store.state.defaultStore + "condition.png";
    },
    alreadycheckedImage() {
      return this.$store.state.defaultStore + "alreadychecked.png";
    },
    rentalImage() {
      return this.$store.state.defaultStore + "rental.png";
    },
    bailImage() {
      return this.$store.state.defaultStore + "bail.png";
    },
    returnImage() {
      return this.$store.state.defaultStore + "return.png";
    },

    backtorental() {
      this.$router.push("/backoffice/rental/" + this.orderHeaderId);
    },
    async update() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        // orderHeaderId: "string",
        // orderRef: "string",
        orderNumber: this.orderNumber,
        // orderNumberRef: "string",
        branchId: this.currentBranchId,
        orderStatus: "รอรับชุด",
        remark1: this.remark1,
        // remark2: "string",
        // remark3: "string",
        // remark4: "string",
        customerId: "string",
        customerName: this.customerName,
        customerAddress: this.customerAddress,
        customerPhone: this.customerPhone,
        // accountNumber: "string",
        // accountName: "string",
        // bankName: "string",
        // transferContact: "string",
        receivingMethod: this.receivingMethod,
        pickupDate: this.startDate,
        returnDate: this.endDate,
        rentalPriceTotal: this.rentalPriceTotal,
        bailTotal: this.bailTotal,
        grandTotal: Number(this.grandTotal),
        rentalDiscount: this.rentalDiscount,
        bailDiscount: this.bailDiscount,
        promotionDiscount: this.promotionDiscount,
        // deduction: 0,
        // deductionDate: "2019-07-14T09:40:20.607Z",
        // bailReturningAmount: 0,
        // bailReturningStatus: "string",
        // bailReturningDate: "2019-07-14T09:40:20.607Z",
        description: "",
        state: true,
        // createDateTime: "",
        createBy: this.currentUser,
        // updateDateTime: "",
        updateBy: this.currentUser
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/" + this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.orderHeader = await responseOrder.data;
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.orderStatus = "รอรับชุด";
          }, 2000);
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 2000);
      }

      // >>>> START TO SAVE HEADER
    },
    // >>>>>> เราต้องการ this.itemsInBranch
    async init(branchId) {
      var allInBranch = await this.loadStock(branchId);
      this.branchStock = await allInBranch;

      // console.log(" branchStock >>>> ", this.branchStock);

      // LOAD ALL ITEMS
      var allProduct = await this.loadProduct();
      this.originalList = await allProduct;
      this.itemsInStock = await allProduct;

      // console.log(" <<<<< itemsInStock >>>> ", this.itemsInStock);

      // INITAILIZE ITEMS in BRANCH

      for (var i = 0; i < allInBranch.length; i++) {
        var anItem = await allProduct.find(
          x => x.productItemId === allInBranch[i].productItemId
        );

        // console.log("[", i, "]  >>>> anITEM >>>> ", anItem);

        if (anItem !== null && anItem !== undefined) {
          if (anItem.mainImage) {
            anItem.mainImage =
              (await this.$store.state.imageStore) + anItem.mainImage;
          } else {
            anItem.mainImage =
              (await this.$store.state.imageStore) + "defaultImage.png";
          }

          // ADDITIONAL FIELD
          anItem.amountInBranch = allInBranch[i].inStock;
          anItem.reservedQuantity = 1;
          anItem.notAvailable = false;

          // console.log("ITEM IN BRANCH ");

          this.itemsInBranch.push(anItem);
        }
      }

      // console.log("this.itemsInBranch >>>> ", this.itemsInBranch);
    },

    async loadProduct() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    // >>>>>> เราต้องการ this.displayItems
    async comeback(orderid) {
      // console.log('@@@@@@@@@@@@@Comback');

      var list = [];
      // this.theLogo = this.$store.state.defaultStore + "logo.png";
      this.orderHeaderId = this.$route.params.orderid;
      this.currentUser = JSON.parse(localStorage.getItem("username"));

      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/receipt-info/" + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

        // console.log("this.orderInfo >>> ",this.orderInfo);

        this.currentBranchId = this.orderInfo.orderHeader.branchId;

        this.orderNumber = this.orderInfo.orderHeader.orderNumber;
        this.customerName = this.orderInfo.orderHeader.customerName;
        this.customerAddress = this.orderInfo.orderHeader.customerAddress;
        this.customerPhone = this.orderInfo.orderHeader.customerPhone;
        this.receivingMethod = this.orderInfo.orderHeader.receivingMethod;
        this.startDate = this.orderInfo.orderHeader.pickupDate;
        this.endDate = this.orderInfo.orderHeader.returnDate;
        this.rentalPriceTotal = this.orderInfo.orderHeader.rentalPriceTotal;
        this.bailTotal = this.orderInfo.orderHeader.bailTotal;
        this.grandTotal = this.orderInfo.orderHeader.grandTotal;
        this.rentalDiscount = this.orderInfo.orderHeader.rentalDiscount;
        this.bailDiscount = this.orderInfo.orderHeader.bailDiscount;
        this.promotionDiscount = this.orderInfo.orderHeader.promotionDiscount;
        this.orderStatus = this.orderInfo.orderHeader.orderStatus;
        this.remark1 = this.orderInfo.orderHeader.remark1;

        //*** BEGIN 15Nov2021 เพิ่มภาษี */
        this.voucherDiscount = this.orderInfo.orderHeader.voucherDiscount;
        this.pointDiscount = this.orderInfo.orderHeader.pointDiscount;
        this.netPrice = this.orderInfo.orderHeader.netPrice;
        this.vat = this.orderInfo.orderHeader.vat;
        this.beforeVat = this.orderInfo.orderHeader.beforeVat;
        this.inVat = this.orderInfo.orderHeader.inVat;
        this.vatSystem = this.orderInfo.orderHeader.vatSystem;
        this.getPoint = this.orderInfo.orderHeader.getPoint;
        this.taxRate = this.orderInfo.orderHeader.taxRate;
        //*** END 15Nov2021 เพิ่มภาษี */

        this.detail = this.orderInfo.orderDetail;
        var dlist = this.orderInfo.orderDetail;

        // console.log('this.orderInfo.orderHeader >>>>> ',this.orderInfo.orderHeader);

        // console.log('@@@@@@@@@@@@@Comback this.netPrice ', this.netPrice);
        // console.log('@@@@@@@@@@@@@Comback this.vatSystem ', this.vatSystem);
        // console.log('@@@@@@@@@@@@@Comback this.inVat ', this.inVat);

        for (var d = 0; d < dlist.length; d++) {
          var theItm = {};
          theItm = Object.assign({}, dlist[d]);
          theItm.removed = dlist[d].flag1;
          this.displayItems.push(theItm);
          this.orders.push(theItm);
          list.push(theItm);
        }

        this.shopinfo = this.orderInfo.shopInfo;

        this.aBranch = this.orderInfo.branch;
      } catch (err) {
        this.$swal({
          icon: "warning",
          title: "ไม่สามารถรับข้อมูลได้"
        });
      }

      //***********************************************************/
      //***********************************************************/
      //***********************************************************/

      return list;
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  async created() {
    // var rp = this.$route.params.orderno;
    // alert(rp);

    // INITIALIZE DATA
    await this.$store.dispatch("shop/fetch");
    // 1. ORDER HEADER
    this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed

    // 2. CUSTOMER
    this.customerInfo = "";
    this.customerName = "";
    this.customerAddress = "";
    this.customerPhone = "";

    // 3. DELIVERY
    this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    this.receivingDate = null;
    this.returnDate = null;
    this.addtocart = "";

    // 4. MONETARY

    // this.bailDiscount = 300;
    // this.rentalDiscount = 100;
    this.promotionDiscount = 0;

    this.rentalPriceTotal = 0;
    this.bailTotal = 0;
    this.grandTotal = 0;

    this.orderHeaderId = this.$route.params.orderid;
    this.currentUser = JSON.parse(localStorage.getItem("username"));

    // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

    await this.comeback(this.orderHeaderId);

    this.shopLineScore = this.getShopLineScore(this.shopinfo);

    // // *** BEGIN 2021-04-13 ปรับค่า grandtotal

    // if(this.taxInfo.vatSystem === true)
    // {
    //   this.grandTotal = this.rentalPriceTotal_final(this.rentalPriceTotal, this.rentalDiscount) + ( this.bailTotal - this.bailDiscount )   ;
    // }

    //  // *** END 2021-04-13 ปรับค่า grandtotal

    // for (var i = 0; i < this.orders.length; i++) {
    //   this.rentalPriceTotal =
    //     this.rentalPriceTotal +
    //     this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

    //   this.bailTotal =
    //     this.bailTotal + this.orders[i].bail * this.orders[i].reservedQuantity;
    // }
    // this.grandTotal = this.grandTotal + this.rentalPriceTotal + this.bailTotal;

    // console.log("this.grandTotal >>> ", this.grandTotal);
  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    VueBarcode
  },

  computed: {
    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo() {
      return this.$store.getters["shop/tax"];
    }
  },

  watch: {
    settlementBox(value) {
      console.log("settlementBox >>> ", value);
      this.$store.commit("shop/CHANGE_SETTLEMENTBOX", value);
    }
  }
};
</script>

<style>
input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "mitr";
  font-size: 16px;
}
.grand-total-discount {
  font-family: "mitr";
  font-size: 12px;
}
.table-font {
  font-family: "mitr";
  font-size: 14px;
}

.the-black {
  color: #494949;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.vue-barcode-element {
  width: 200px !important;
  height: 90px;
}
</style>